<template>
  <div class="about">
    <h1>All rights reseved to WMSolutions Romania</h1>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style lang="scss">

</style>

<style scoped>

</style>
